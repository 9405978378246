import React, { useEffect, useRef, useState } from 'react';
import { FaCheck, FaHammer, FaTimes } from 'react-icons/fa';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import ptBR from 'timeago.js/lib/lang/pt_BR';

import Spinner from '../../components/Spinner';
import { gatsbyCloudBuildUrl } from '../../config/gatsby-cloud';

import { useGatsbyCloudBuild } from './use-gatsby-cloud-build';

import '../../css/global.scss';
import './Build.scss';

timeago.register('pt_BR', ptBR);

const Build: React.FC = () => {
  const { latestBuildTime, buildStatus, requestBuild } = useGatsbyCloudBuild({
    buildTimeUrl: 'https://experienciasincriveis.netlify.app/build.txt',
    gatsbyCloudBuildUrl,
    // gatsbyCloudBuildUrl: 'https://cors-test.appspot.com/test',
  });

  const [buildTimer, setBuildTimer] = useState(0);
  const buildTimerIntervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (buildStatus?.id !== 'building' && buildTimerIntervalRef.current) {
      clearInterval(buildTimerIntervalRef.current);
      setBuildTimer(0);
    }

    if (buildStatus?.id === 'building') {
      buildTimerIntervalRef.current = setInterval(() => {
        setBuildTimer((prevState) => prevState + 1);
      }, 1000);
    }
  }, [buildStatus?.id]);

  return (
    <main className="build">
      {!latestBuildTime && !buildStatus && (
        <Spinner label="Carregando..." />
      )}

      {latestBuildTime && (
        <h5>
          <span>Último build:</span>
          <strong>
            <TimeAgo className="build-time" datetime={latestBuildTime} locale="pt_BR" />
          </strong>
        </h5>
      )}

      {buildStatus?.id === 'success' && (
        <p>
          <span>
            <FaCheck className="build-ico-ok" /> Build feito com sucesso em {buildStatus.secondsElapsed} segundos!
          </span>
        </p>
      )}

      {buildStatus?.id === 'error' && (
        <p>
          <span>
            <FaTimes className="build-ico-error" /> Algo deu errado!
          </span>

          <pre>
            {`error: ${buildStatus.errorType}`}
            {'\n'}
            {`msg: ${buildStatus.errorMessage ?? 'N/A'}`}
          </pre>
        </p>
      )}

      {latestBuildTime && (
        <button
          type="button"
          onClick={(): void => requestBuild()}
          disabled={buildStatus?.id === 'building' || buildStatus?.id === 'requestingBuild'}
        >
          {buildStatus?.id !== 'building' && buildStatus?.id !== 'requestingBuild' && (
            <>
              <FaHammer />
              Iniciar Build
            </>
          )}

          {buildStatus?.id === 'requestingBuild' && (
            <>
              <Spinner />
              Iniciando build...
            </>
          )}

          {buildStatus?.id === 'building' && (
            <>
              <Spinner />
              Build em progresso... ({buildTimer}s)
            </>
          )}
        </button>
      )}
    </main>
  );
};

export default Build;
