/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';

//
type GatsbyCloudBuildStatus = {
  id: 'requestingBuild';
} | {
  id: 'building';
  startTime: number;
} | {
  id: 'success';
  secondsElapsed: number;
} | {
  id: 'error';
  errorType: 'getCurrentBuildTimeError' | 'requestBuildError' | 'verifyBuildTimeout';
  errorMessage?: string;
};

export const useGatsbyCloudBuild = (options: {
  buildTimeUrl: string;
  gatsbyCloudBuildUrl: string;
  verifyBuildInterval?: number;
  verifyBuildTimeout?: number;
}) => {
  const [latestBuildTime, setLatestBuildTime] = useState<Date>();
  const [buildStatus, setBuildStatus] = useState<GatsbyCloudBuildStatus>();

  //
  const getLatestBuildTime = async (): Promise<Date> => {
    const response = await fetch(options.buildTimeUrl);

    if (!response.ok) {
      throw new Error('Response not ok, status ' + response.status);
    }

    const body = await response.text();

    // https://stackoverflow.com/a/44593840
    if (body.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)) {
      const buildTime = new Date(body);
      if (buildTime) return buildTime;
    }

    throw new Error('Received invalid build time format');
  };

  //
  const getAndSetLatestBuildTime = async (): Promise<void> => {
    getLatestBuildTime()
      .then((buildTime) => {
        setLatestBuildTime(buildTime);
      })
      .catch((error) => {
        setLatestBuildTime(undefined);

        setBuildStatus({
          id: 'error',
          errorType: 'getCurrentBuildTimeError',
          errorMessage: error.message,
        });
      });
  };

  // get initial build time
  useEffect(() => {
    getAndSetLatestBuildTime();
  }, []);

  // request build
  useEffect(() => {
    if (!latestBuildTime || buildStatus?.id !== 'requestingBuild') return;

    getAndSetLatestBuildTime()
      .then(() => {
        fetch(options.gatsbyCloudBuildUrl, { method: 'POST' })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Response not ok, status ' + response.status);
            } else {
              setBuildStatus({ id: 'building', startTime: (new Date()).getTime() });
            }
          })
          .catch((error) => {
            setBuildStatus({
              id: 'error',
              errorType: 'requestBuildError',
              errorMessage: error.message,
            });
          });
      });
  }, [buildStatus?.id]);

  // verify build
  useEffect(() => {
    if (!latestBuildTime || buildStatus?.id !== 'building') return;

    let verifyBuildInterval: NodeJS.Timeout | undefined = setInterval(() => {
      getLatestBuildTime()
        .then((buildTime) => {
          if (latestBuildTime && buildTime > latestBuildTime) {
            setLatestBuildTime(buildTime);

            setBuildStatus({
              id: 'success',
              secondsElapsed: Math.round(((new Date()).getTime() - buildStatus.startTime) / 1000),
            });

            if (verifyBuildInterval) {
              clearInterval(verifyBuildInterval);
              verifyBuildInterval = undefined;
            }
          }
        })
        .catch(() => null);
    }, options.verifyBuildInterval ?? 4 * 1000);

    setTimeout(() => {
      if (!verifyBuildInterval) return;

      clearInterval(verifyBuildInterval);

      setBuildStatus({
        id: 'error',
        errorType: 'verifyBuildTimeout',
      });
    }, options.verifyBuildTimeout ?? 5 * 60 * 1000);
  }, [buildStatus?.id]);

  //
  const requestBuild = (): void => setBuildStatus({ id: 'requestingBuild' });

  return {
    latestBuildTime,
    buildStatus,
    requestBuild,
  };
};
